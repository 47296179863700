<template>
  <div class="bgr profile">
    <h1 class="pt-5 pb-5">{{ $t('profileSection.profileTitle') }}</h1>
    <b-container class="pt-4 mt-4 maxw">
      <div class="p-4 mb-4 bg-dark">
        <div class="p-3 text-center">
          <b-container>
            <b-avatar :src="$auth.user.picture" size="8rem"></b-avatar>
          </b-container>
          <div class="pt-2 text-light">
            <h3>{{ $auth.user.name }}</h3>
          </div>
          <div class="text-light">
            {{ $auth.user.email }}
          </div>
          <!-- <div class="text-light">Lang: {{ $auth.user.locale }}</div> -->
          <div
            class="text-light"
            v-if="$auth.user['https://cloud.mitakus.eu/jwt/claims/roles'].join(' | ')"
          >
           {{ $t('profileSection.profileRoles') }} ( {{ $auth.user['https://cloud.mitakus.eu/jwt/claims/roles'].join(' | ') }} )
          </div>
          <div class="text-light">
            {{ $t('profileSection.currentRole') }}  {{ $auth.user['https://cloud.mitakus.eu/jwt/claims/defaultrole'] }}
          </div>
          <div class="text-light">
           {{ $t('profileSection.backendUrl') }}  {{ $auth.user['https://cloud.mitakus.eu/jwt/claims/hurl'] }}
          </div>

          <!-- <div>
            <pre style="color: white;">{{ JSON.stringify($auth.user, null, 2) }}</pre>
          </div> -->
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Profile',
  components: {},
};
</script>

<style scoped>
.profile {
  height: 100vh;
}
</style>
