<template>
  <div>
    <div class="ccm-cookie-declaration">
      <h1>Datenschutzhinweis</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  data() {
    return {};
  },
};
</script>

