<template>
  <div class="ccm-cookie-declaration">
    <h1>Impressum</h1>
  </div>
</template>

<script>
export default {
  name: 'Imprint',
  data() {
    return {};
  },
};
</script>

